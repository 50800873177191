import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
`

const JobListing = ({ children, className }) => {
	const ref = useRef(null)

	useEffect(() => {
		console.log(ref)
		const script = document.createElement('script')
			script.async = true
			script.defer = true
			script.src = 'https://app.jazz.co/widgets/basic/create/NSSI'
		
			ref.current.appendChild(script)
	}, [])

	return (
		<div ref={ref} setTheme="lightGrey" className={className}>
			JOBS
		</div>
	)
}

export default JobListing
