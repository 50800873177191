import React, { Fragment } from "react"
import { graphql } from "gatsby"
import * as PropTypes from "prop-types"

import SEO from "src/components/SEO"
import ComponentRenderer from "src/components/ComponentRenderer"
import Header from "src/components/Header"
import Footer from "src/components/Footer"
import VideoModal from 'src/components/VideoModal'
import JobListing from 'src/components/JobListing'
import Video from 'src/components/Video'
import PwProtectionLogin from "src/components/PwProtectionLogin"
import { withModalContext } from 'src/context/ModalContext'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class PageTemplate extends React.Component {
  state = {
    pwProtected: false,
    enteredPassword: "",
    pwError: false,
  }

  componentDidMount = () => {
    const page = this.props.data.allContentfulPage.edges[0].node
    const { password } = page
    if (password) {
      this.setState({
        pwProtected: true,
        password: password,
      })
    }
  }

  unlockPage = () => {
    const { enteredPassword } = this.state
    const page = this.props.data.allContentfulPage.edges[0].node
    const { password } = page
    if (enteredPassword === password) {
      console.log("unlock")
      this.setState({ pwProtected: false })
    } else {
      console.log("nope!")
      this.setState({ pwError: true })
    }
  }

  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const site = this.props.data.allContentfulSiteSettings.edges.filter(
      (edge) => !edge.node.title.includes("PLACEHOLDER")
    )[0].node
    const page = this.props.data.allContentfulPage.edges[0].node
    const { sections, slug, password, navigation } = page

    let hasAtf = (sections[0]?.__typename === "ContentfulWideMedia" && sections[0]?.width === "fullWidth") || (sections[0]?.theme === "dark")
    const seo = page.seo
    const { pwProtected, enteredPassword, pwError } = this.state

    const { modalContext } = this.props
    // password protection logic
    if (pwProtected && password) {
      return (
        <div>
          <Header
            // headerNavigation={site.headerNavigation}
            // headerDrawerBottomLinks={site.headerDrawerBottomLinks}
            // headerLinks={site.headerLinks}
            // headerButtons={site.headerButtons}
            location={slug}
            hasAtf={hasAtf}
            bannerText={site.bannerText}
            bannerColor={site.bannerColor}
            site={site}
            headerPlaceholder={false}
            collapsed={true}
            navigation={navigation !== "hidden"}
          />
          <PwProtectionLogin
            handleInput={this.handleInput}
            unlockPage={this.unlockPage}
            enteredPassword={enteredPassword}
            error={pwError}
          />
          <h4 style={{ position: "fixed", bottom: 0, left: 0 }}></h4>
        </div>
      )
    }

    return (
      <Fragment>
        <SEO
          title={page?.title}
          description={seo && seo.description && seo.description?.description}
          siteSettings={site}
          keywords={seo && seo.keywords}
          shareImage={
            seo && seo.shareImage && "https:" + seo.shareImage?.file?.url
          }
        />
        <Header
          // headerNavigation={site.headerNavigation}
          // headerDrawerBottomLinks={site.headerDrawerBottomLinks}
          // headerLinks={site.headerLinks}
          // headerButtons={site.headerButtons}
          location={slug}
          hasAtf={hasAtf}
          bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          site={site}
          navigation={navigation !== "hidden"}
        />

        {/* <JobListing/> */}

        {sections &&
          sections.map((section, index) => {
            if (
              !section.theme ||
              section.theme == null ||
              section.theme == "bgColor"
            ) {
              section.theme = "default"
            }
            const prevTheme =
              index !== 0 &&
              sections[index - 1] &&
              sections[index - 1].theme !== null
                ? sections[index - 1].theme
                : "default"
            const prevFullWidth =
              index !== 0 &&
              sections[index - 1] &&
              sections[index - 1].width === "fullWidth"
            const nextTheme =
              index !== sections.length - 1 &&
              sections[index + 1] &&
              sections[index + 1].theme !== null
                ? sections[index + 1].theme
                : "default"
            const nextFullWidth =
              index !== sections.length - 1 &&
              sections[index + 1] &&
              sections[index + 1].width === "fullWidth"
            const lastSection = sections.length === index + 1
            return (
              <ComponentRenderer
                prevTheme={prevFullWidth ? false : prevTheme}
                nextTheme={nextFullWidth ? false : nextTheme}
                isFirstSection={index === 0}
                isLastSection={lastSection}
                key={section.id}
                item={section}
                index={index}
              />
            )
          })}

        <Footer {...site} navigation={navigation !== "hidden"} />
        <VideoModal />
      </Fragment>
    )
  }
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query ($id: String!) {
    allContentfulSiteSettings(
      filter: { internalName: { nin: "PLACEHOLDER Site Settings" } }
    ) {
      edges {
        node {
          ...SiteSettings
        }
      }
    }
    allContentfulPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          navigation
          sections {
            ...Columns
            ...FiftyFifty
            ...TextSection
            ...TextSectionV2
            ...WideMedia
            ...FiftyFiftyV2
          }
          password
          seo {
            description {
              description
            }
            keywords
            shareImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default withModalContext(PageTemplate)
